<template>
    <div class="tableList toevtlist">
      <div class="table">
        <el-table :data="tableData" ref="tabledeatailData" max-height="350"   style="width: 100%">
          <el-table-column prop="sequenceNumber" label="状态" width="80px">
          </el-table-column>
          <el-table-column prop="mode" label="评分方式" >
          </el-table-column>
          <el-table-column prop="supperCode" label="供应商编码" width="130px">
          </el-table-column>
          <el-table-column prop="supperName" label="供应商名称" width="130px">
          </el-table-column>
          <el-table-column prop="categoryName" label="采购品类名称" width="140px">
          </el-table-column>
          <el-table-column prop="items" label="考评细项" width="140px">
          </el-table-column>
          <el-table-column prop="standard" label="评分标准" width="140px">
          </el-table-column>
          <el-table-column prop="score" label="得分" width="140px">
          </el-table-column>
          <el-table-column prop="personLiable" label="责任人" width="140px">
          </el-table-column>
          <el-table-column prop="branchStart" label="合理分值从" width="140px">
          </el-table-column>
          <el-table-column prop="branchEnd" label="合理分值至" width="140px">
          </el-table-column>
          <el-table-column prop="remake" label="备注" width="140px">
          </el-table-column>
        </el-table>
      </div>
    </div>
</template>

<script>
import Component from '@/assets/js/components.js'
// import { request } from '@/assets/js/http.js'
export default {
  components: Component.components,
  name: 'fileFillingTable',
  props: ['newParnData'],
  data () {
    return {
      parentId: '',
      tableData: []
    }
  },
  created () {
  },
  methods: {}
}
</script>
<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
@import '@/assets/css/elForm.scss';
.toevtlist{
  .header{
    position: relative;
    .exportData{
      position: absolute;
      left: 90px;
      top: 0;
    }
  }
}
.tableList {
  .table{
    :deep(.el-table td) {
        padding: 4px 0;
    }
    :deep(.el-input__inner){
      height:34px;
      line-height: 34px;
    }
  }
  .is-disabled{
    :deep(.el-input__inner){
      background: none;
      border: none;
      color: #606266;
      padding: 0;
    }
    :deep(.el-select__caret){
      display: none;
    }
  }
}
</style>
